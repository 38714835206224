import {
    _dispatch,
    BLOG_CATEGORY_LIST_SUCCESS,
    DELETE_BLOG_CAT_SUCCESS,
    BLOG_LIST_SUCCESS,
    DELETE_BLOG_SUCCESS
    
  } from '../actions/blogs';
  
  const INITIAL_STATE = {status: null, blog_list: {}, cuisine_list:[], activePage: 1, shop_docs:[], cat_list: [], gallery_list: [], banner_list: {}};
  
  export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
      
      case BLOG_CATEGORY_LIST_SUCCESS:
        return _dispatch({ ...state, cat_list: action.payload}, true, 'catlist');
        break;
      case DELETE_BLOG_CAT_SUCCESS:
            return (() => {
              const data = [...state.cat_list];
              console.log(data);
              data.splice(action.payload, 1);
              return _dispatch({ ...state, cat_list: data}, true, 'catlist');
            })();
            break;
     
       case BLOG_LIST_SUCCESS:
         
            return _dispatch({ ...state, blog_list: action.payload, activePage: action.activePage}, true, 'bloglist');
            break;

         case DELETE_BLOG_SUCCESS:
                    return (() => {
                      const data = {...state.blog_list};
                      data.blogs.splice(action.payload, 1);
                      return _dispatch({ ...state, blog_list: data}, true, 'bloglist');
                    })();
                    break;
            default:
        return state;
    }
  
  }
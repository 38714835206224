import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};
export const BLOG_CATEGORY_LIST_SUCCESS = 'BLOG_CATEGORY_LIST_SUCCESS';
export const DELETE_BLOG_CAT_SUCCESS = 'DELETE_BLOG_CAT_SUCCESS';
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';

const ROOT_URL = API_ROOT;

export function addNewBlogCateogry(props){
    return axios({
      method: 'POST',
      data: props,
      url: `${ROOT_URL}/admin/addBlogCategory`
    });
  }
  export function categoryListing(params) {
    return axios({
      method: 'GET',
      params: params,
      url: `${ROOT_URL}/admin/getBlogCategories`,
    });
  }
  export function categoryListingSuccess(payload, activePage){
    return {
        type: BLOG_CATEGORY_LIST_SUCCESS,
        payload: payload,
        activePage
      }
  }
  export function deleteBlogCategory(data) {
    return axios({
      method: 'DELETE',
      data: data,
      url: `${ROOT_URL}/admin/deleteBlogcategory`
    });
  }
  export function deleteBlogCategorySuccess(payload) {
    return {
      type: DELETE_BLOG_CAT_SUCCESS,
      payload: payload
    };
  }
  export function addNewBlog(props){
    return axios({
      method: 'POST',
      data: props,
      url: `${ROOT_URL}/admin/saveBlog`
    });
  }
  export function blogListing(params){
    return axios({
        method: 'GET',
        params: params,
        url: `${ROOT_URL}/admin/getBlogs`
      });
  }
  export function getBlogById(params){
    return axios({
      method: 'GET',
      params: params,
      url: `${ROOT_URL}/admin/getBlogById`
    });
  }
  export function blogListingSuccess(payload, activePage){
    return {
        type: BLOG_LIST_SUCCESS,
        payload: payload,
        activePage
      }
  }
  export function changeBlogStatus(data) {
    return axios({
      method: 'PUT',
      data: data,
      url: `${ROOT_URL}/admin/updateBlogStatus`
    });
  }
  export function deleteBlogSuccess(payload) {
    return {
      type: DELETE_BLOG_SUCCESS,
      payload: payload
    };
  }
  export function productListing(params) {
    return axios({
      method: 'GET',
      params: params,
      url: `${ROOT_URL}/admin/grocery/getBlogItems`,
      
    });
  }

  export function getBlogCategoryById(params){
    return axios({
      method: 'GET',
      params: params,
      url: `${ROOT_URL}/admin/getBlogCategoryById`
    });
  }
import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};
const ROOT_URL = API_ROOT;
var token = "";
export const DELIVERY_LIST_SUCCESS = 'DELIVERY_LIST_SUCCESS';
export const DELETE_DELIVERY_RULE_SUCCESS = 'DELETE_DELIVERY_RULE_SUCCESS';
export const REQUIRED_COUNTER_SUCCESS = 'REQUIRED_COUNTER_SUCCESS';
export const UPDATE_REQUIRED_COUNTER_SUCCESS = 'UPDATE_REQUIRED_COUNTER_SUCCESS';
export const MANAGE_REQUIRED_COUNTER_SUCCESS = 'MANAGE_REQUIRED_COUNTER_SUCCESS';

export const PROMOTION_LIST_SUCCESS = 'PROMOTION_LIST_SUCCESS';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';

export function saveSettings(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/saveSettings`
  });
}

export function settingsList(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getSettings`,
  });
}

export function deliverySettingList(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getshopdeliverysetting`,
  });
}

export function deliverySettingListSuccess(payload){
  return {
      type: DELIVERY_LIST_SUCCESS,
      payload: payload
    }
}

export function saveDeliveryRule(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/adddeliveryrule`
  });
}

export function getDeliveryRuleById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getdeliveryrulebyid`,
  });
}

export function deleteDeliveryRule(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/deletedeliveryrule`
  });
}


export function deleteDeliveryRuleSuccess(payload) {
  return {
    type: DELETE_DELIVERY_RULE_SUCCESS,
    payload: payload
  };
}

export function saveAppVersionDetail(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/saveappversiondetail`
  });
}

export function getAppVersion(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getappversion`,
  });
}

export function saveReferToFriendSettings(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/savereferralsetting`
  });
}

export function referToFriendDetail(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getreferfrienddetail`,
  });
}

export function saveUserAppSettings(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/saveuserappsettings`
  });
}

export function userAppSettingsList(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getuserappsettings`,
  });
}

export function getRequiredCounter(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getRequiredCounter`,
  });
}

export function getRequiredCounterSuccess(payload) {
  return {
    type: REQUIRED_COUNTER_SUCCESS,
    payload: payload
  };
}

export function updateRequiredCounterSuccess(payload){
  return {
    type: UPDATE_REQUIRED_COUNTER_SUCCESS,
    payload: payload
  };
}

export function manageRequiredCounterSuccess(payload, counter){
  return {
    type: MANAGE_REQUIRED_COUNTER_SUCCESS,
    payload: payload,
    counter: counter
  };
}

export function countryList(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getCountries`,
  });
}

export function getCountryCurrencyCharges(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getCountryCurrencyCharges`,
  });
}

export function addCountryCurrencyCharges(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/addCountryCurrencyCharges`
  });
}

export function editCountryCurrencyCharges(props){
  return axios({
    method: 'PATCH',
    data: props,
    url: `${ROOT_URL}/admin/editCountryCurrencyCharges`
  });
}

export function getCountryCurrencyChargesById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getCountryCurrencyCharges`,
  });
}

export function deleteCountryCurrencyCharges(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/admin/deleteCountryCurrencyCharges`
  });
}

export function getZaiAccount(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/zai/getZaiAccount`,
  });
}

export function setDefaultZaiAccount(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/zai/setDefaultAccount`
  });
}

export function transferFundsToBank(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/zai/transferFundsToBank`
  });
}

export function transferFundsToWallet(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/zai/transferFundsToWallet`
  });
}

export function transferMoneyCron(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/zai/cron/transferMoney`,
  });
}


export function addPromotion(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/createPromotion`
  });
}
export function editPromotion(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/updatePromotion`
  });
}

export function promotionListing(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/user/getPromotionListing`
  });
}
export function getPromotionById(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getPromotion`
  });
}
export function promotionListingSuccess(payload, activePage){
  return {
    type: PROMOTION_LIST_SUCCESS,
    payload: payload,
    activePage
  }
}
export function changePromotionStatus(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/admin/updatePromotionStatus`
  });
}

export function deletePromotion(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/deletePromotion`
  });
}
export function deletePromotionSuccess(payload) {
  return {
    type: DELETE_PROMOTION_SUCCESS,
    payload: payload
  };
}



export function getNovattiBankDetail(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/webhook/getBankDetails`,
  });
}

export function getNovattiSourceAccount(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/webhook/getSourceAccountDetails`,
  });
}

export function setDefaultSourceAccount(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/webhook/setDefaultSourceAccount`
  });
}

export function setDefaultNovattiBank(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/webhook/setDefaultNovattiBank`
  });
}

export function transferFundsToNovattiBank(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/webhook/generateTransfer`
  });
}
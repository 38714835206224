import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import User from './users';
import Setting from './settings';
import Notification from './notifications';
import Communication from './communication';
import Blog from './blog';

const appReducer = combineReducers({
  form: formReducer,
  User,
  Setting,
  Notification,
  Communication,
  Blog
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;
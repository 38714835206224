import {load} from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  ROOT: { 
  	path: '/',
  	exact: true,
  	render: () => <Redirect to="/login" />
  },
  DASHBOARD: { 
  	path: '/dashboard',
    component: load('Dashboard'),
    acl: 'Dashboard'
  },
  DASHBOARDANALYTICS: { 
    path: '/dashboard',
    exact: true,
    component: load('dashboard/Dashboard'),
    acl: 'Dashboard'
  },
  TRANSACTIONS: { 
    path: '/dashboard/transactions',
    exact: true,
    component: load('users/TransactionsListing'),
    acl: 'TransactionsListing'
  },
  SETTING: { 
    path: '/dashboard/settings',
    exact: true,
    component: load('setting/Settings'),
    acl: 'ManageSettings'
  },
  LOGOUT: { 
    path: '/logout',
    component: load('Logout')
  },
  LOGIN: { 
  	path: '/login',
  	exact: true,
    component: load('Login')
  },
  MANAGEFAQ: { 
    path: '/dashboard/managefaq',
    exact: true,
    component: load('faq/ManageFaq'),
    acl: 'ManageFAQs'
  },
  ADDFAQ: { 
    path: '/dashboard/addfaq',
    exact: true,
    component: load('faq/AddFaq'),
    acl: 'ManageFAQs'
  },
  EDITFAQ: { 
    path: '/dashboard/editfaq/:index',
    exact: true,
    component: load('faq/EditFaq'),
    acl: 'ManageFAQs'
  },
  CUSTOMERS: { 
    path: '/dashboard/customers',
    exact: true,
    component: load('users/Customers'),
    acl: 'UsersListing'
  },
  MANAGETESTIMONIAL: { 
    path: '/dashboard/managetestimonial',
    exact: true,
    component: load('testimonial/ManageTestimonial'),
    acl: 'ManageTestimonials' 
  },
  ADDTESTIMONIAL: { 
    path: '/dashboard/addtestimonial',
    exact: true,
    component: load('testimonial/AddTestimonial'),
    acl: 'ManageTestimonials' 
  },
  EDITTESTIMONIAL: { 
    path: '/dashboard/edittestimonial/:index',
    exact: true,
    component: load('testimonial/EditTestimonial'),
    acl: 'ManageTestimonials' 
  },
  KYCREQUEST: { 
    path: '/dashboard/kycrequest',
    exact: true,
    component: load('users/KycRequest'),
    acl: 'UsersListing' 
  },
  MANAGECOUNTRYCURRENCY: { 
    path: '/dashboard/managecountrycurrency',
    exact: true,
    component: load('common/ManageCountryCurrency'),
    acl: 'ManageSettings' 
  },
  ADDCOUNTRYCURRENCY: { 
    path: '/dashboard/addcountrycurrency',
    exact: true,
    component: load('common/AddCountryCurrency'),
    acl: 'ManageSettings' 
  },
  EDITCOUNTRYCURRENCY: { 
    path: '/dashboard/editcountrycurrency/:index',
    exact: true,
    component: load('common/EditCountryCurrency'),
    acl: 'ManageSettings' 
  },
  // MANAGEROLES: { 
  //   path: '/dashboard/manageroles',
  //   exact: true,
  //   component: load('users/ManageRoles'),
  //   acl: 'ROLES_VIEW_ONLY'
  // },
  // ADDROLES: { 
  //   path: '/dashboard/addrole',
  //   exact: true,
  //   component: load('users/AddRole'),
  //   acl: 'ROLES_ADD_EDIT'
  // },
  // EDITROLES: { 
  //   path: '/dashboard/editrole/:index',
  //   exact: true,
  //   component: load('users/EditRole'),
  //   acl: 'ROLES_ADD_EDIT'
  // },
  MANAGESTAFF: { 
    path: '/dashboard/managestaff',
    exact: true,
    component: load('users/ManageStaff'),
    acl: 'ManageStaff'
  },
  ADDSTAFF: { 
    path: '/dashboard/addstaff',
    exact: true,
    component: load('users/AddStaff'),
    acl: 'ManageStaff'
  },
  EDITSTAFF: { 
    path: '/dashboard/editstaff/:index',
    exact: true,
    component: load('users/EditStaff'),
    acl: 'ManageStaff'
  },
  MANAGECOMMUNICATIONMEDIUM: { 
    path: '/dashboard/managecommunicationmedium',
    exact: true,
    component: load('communication/ManageCommunicationMedium'),
    acl: 'UsersListing' 
  },
  ADDCOMMUNICATIONMEDIUM: { 
    path: '/dashboard/addcommunicationmedium',
    exact: true,
    component: load('communication/AddCommunicationMedium'),
    acl: 'UsersListing' 
  },

  MANAGEBLOGCATEGORY: { 
    path: '/dashboard/manageblogcategory',
    exact: true,
    component: load('blogs/ManageBlogCategory'),
    acl: 'ManageBlog' 
  },
  BLOGLISTING: { 
    path: '/dashboard/bloglisting',
    exact: true,
    component: load('blogs/Listing'),
    acl: 'ManageBlog' 
  },
  ADDBLOGCATEGORY: { 
    path: '/dashboard/addblogcategory',
    exact: true,
    component: load('blogs/AddBlogCategory'),
    acl: 'ManageBlog' 
  },
  EDITBLOGCATEGORY: { 
    path: '/dashboard/editblogcategory/:index',
    exact: true,
    component: load('blogs/EditBlogCategory'),
    acl: 'ManageBlog' 
  },
  ADDBLOG: { 
    path: '/dashboard/addblog',
    exact: true,
    component: load('blogs/AddBlog'),
    acl: 'ManageBlog' 
  },
  EDITBLOG: { 
    path: '/dashboard/editblog/:index',
    exact: true,
    component: load('blogs/EditBlog'),
    acl: 'ManageBlog'
  },
  CURRENCY_CONVERSION: { 
    path: '/dashboard/currencyconversion',
    exact: true,
    component: load('currencyconversion/CurrencyConversion'),
    acl: 'ManageSettings' 
  },
  EDIT_CURRENCY_CONVERSION: { 
    path: '/dashboard/editcurrencyconversion/:index',
    exact: true,
    component: load('currencyconversion/EditCurrencyConversion'),
    acl: 'ManageSettings'
  },
  MANAGE_ZAI_ACCOUNTS: { 
    path: '/dashboard/zai-accounts',
    exact: true,
    component: load('setting/ZaiAccounts'),
    acl: 'ManageSettings' 
  },
  ZAI_TRANSACTIONS: { 
    path: '/dashboard/zai-transactions',
    exact: true,
    component: load('users/ZaiTransactionsListing'),
    acl: 'TransactionsListing'
  },
  NOVATTI_TRANSACTIONS: { 
    path: '/dashboard/novatti-transactions',
    exact: true,
    component: load('users/NovattiTransactionsListing'),
    acl: 'TransactionsListing'
  },
  MANAGE_NOVATTI_ACCOUNTS: { 
    path: '/dashboard/novatti-accounts',
    exact: true,
    component: load('setting/NovattiAccounts'),
    acl: 'ManageSettings' 
  },
  MANAGEPROMOTION: { 
    path: '/dashboard/promotion',
    exact: true,
    component: load('setting/ManagePromotion'),
    acl: 'ManageSettings'
  },
  ADDPROMOTION: { 
    path: '/dashboard/addpromotion',
    exact: true,
    component: load('setting/AddPromotion'),
    acl: 'ManageSettings'
  },
  EDITPROMOTION: { 
    path: '/dashboard/editpromotion/:index',
    exact: true,
    component: load('setting/EditPromotion'),
    acl: 'ManageSettings'
  },

  NOTFOUND: { 
    component: load('Sidebar')
  }
};